import React from 'react';
import { Helmet } from 'react-helmet';

import GoogleAdsMasthead from '../components/google-ads-page/GoogleAdsMasthead';
import GoogleAdsSideImageInfoSection from '../components/google-ads-page/GoogleAdsSideImageInfoSection';
import DetailList from '../components/DetailList';
import CtaBar from '../components/CtaBar';
import GoogleAdsServicesTiles from '../components/google-ads-page/GoogleAdsServicesTiles';
import LocationsSearchMap from '../components/LocationsSearchMap';

import { PageWrap } from '../styles/common';

export default ({ pageContent, businessServices, locations }) => {
	return (
		<>
			<Helmet>
				<title>{pageContent.name} | Spergel Corporate</title>
			</Helmet>
			<PageWrap>
				<GoogleAdsMasthead
					image={pageContent.masthead.image}
					title={pageContent.masthead.title}
					height={`100vh`}
				/>
				<GoogleAdsSideImageInfoSection
					image={pageContent.info_section.side_image}
					mobileImage={pageContent.info_section.side_image_mobile}
					imageAlt={`The Spergel Team`}
					mainContent={pageContent.info_section.info_content}
				/>
				<DetailList detail_list={pageContent.detail_list} itemsShowing={true} />
				<CtaBar>
					<h3>
						Let's start a conversation.
						<br />
						<a href="/contact">Contact us today.</a>
					</h3>
				</CtaBar>
				<GoogleAdsServicesTiles services={businessServices} />
				<LocationsSearchMap locations={locations} zoom={7} />
				<CtaBar>
					<h3>
						<a href="/contact">Contact us today</a> for a free consultation.
					</h3>
				</CtaBar>
			</PageWrap>
		</>
	);
};
