import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import GoogleAdsPageTemplate from '../templates/GoogleAdsPageTemplate';

const GoogleAdsPage = ({ data }) => {
	const pageContent = data.googleAdsPageJson;
	const businessServices = data.businessServices.edges.map((edge) => ({
		name: edge.node.display_name,
		detail_list: edge.node.detail_list,
		slug: edge.node.fields.slug,
		image: edge.node.tile_image,
	}));
	const locations = data.locations.edges.map((edge) => ({
		city: edge.node.city,
		address_line_1: edge.node.address_line_1,
		address_line_2: edge.node.address_line_2,
		phone: edge.node.phone,
		email: edge.node.email,
		coords: edge.node.coords,
	}));

	return (
		<Layout page={'googleAds'}>
			<GoogleAdsPageTemplate
				pageContent={pageContent}
				businessServices={businessServices}
				locations={locations}
			/>
		</Layout>
	);
};

export default GoogleAdsPage;

export const GoogleAdsPageQuery = graphql`
	query GoogleAdsPage {
		googleAdsPageJson {
			name
			masthead {
				title
				image
			}
			info_section {
				side_image
				side_image_mobile
				info_content
			}
			detail_list {
				heading
				content
			}
			services_section {
				heading
				content
			}
		}
		businessServices: allServicesJson(
			filter: { for: { eq: "business owners" } }
			sort: { order: ASC, fields: [order] }
		) {
			edges {
				node {
					display_name
					detail_list {
						heading
					}
					tile_image
					fields {
						slug
					}
				}
			}
		}
		locations: allLocationsJson(sort: { fields: [order], order: ASC }) {
			edges {
				node {
					city
					address_line_1
					address_line_2
					phone
					email
					coords {
						latitude
						longitude
					}
				}
			}
		}
	}
`;
