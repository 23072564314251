import React from 'react';
import styled from 'styled-components';

//import Columns from 'react-bulma-components/lib/components/columns';

export default ({ location, setCenter }) => {
	return (
		<TileWrap>
			<h3
				onClick={() =>
					setCenter({
						lat: location.coords.latitude,
						lng: location.coords.longitude,
					})
				}
			>
				{location.city}
			</h3>
			<div className="addresses">
				<p>{location.address_line_1}</p>
				<p>{location.address_line_2}</p>
			</div>
			<div className="details">
				<div className="columns">
					<div className="column">
						<p>Telephone:</p>
					</div>
					<div className="column">
						<p>{location.phone}</p>
					</div>
				</div>
				<div className="columns">
					<div className="column">
						<p>Email: </p>
					</div>
					<div className="column">
						<p>{location.email}</p>
					</div>
				</div>
			</div>
		</TileWrap>
	);
};

const TileWrap = styled.div`
	background-color: #f2f2f2;
	padding: 0.5rem 2rem;
	max-width: 30rem;
	margin: 0.5rem auto;
	h3 {
		margin: 0;
		text-decoration: underline;
		cursor: pointer;
	}
	p {
		margin: 0;
	}
	.addresses {
		margin-bottom: 1rem;
	}
	.details {
		.columns {
			margin-bottom: 0;
			.column {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}
		p {
			margin: 0;
		}
	}
`;
