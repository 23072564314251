import React from 'react';
import styled from 'styled-components';

import common from '../../styles/common';
import mdToHtml from '../../util/mdToHtml';

const Wrap = styled.div`
	margin-bottom: 0;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: stretch;
	flex-wrap: wrap;
	width: 100%;

	@media (max-width: 1220px) {
		flex-direction: column;
	}

	.image-column {
		width: 40%;

		@media (max-width: 1220px) {
			width: 100%;
		}

		@media (min-width: 1221px) and (max-width: 1320px) {
			width: 50%;
		}

		@media (min-width: 1000px) {
			margin-bottom: -3px;
		}
	}

	.content-column {
		width: 60%;

		h2 {
			font-weight: 600;
		}

		p {
			font-family: ${common.baseFont};
			font-size: 1.3rem;
			line-height: 2;
		}

		@media (max-width: 1220px) {
			width: 100%;
		}

		@media (min-width: 1221px) and (max-width: 1320px) {
			width: 50%;
		}

		@media (min-width: 1000px) {
			padding-left: 60px;
		}
	}
`;

const ImageWrap = styled.div`
	img {
		height: auto;
		max-width: 100%;
		width: 100%;

		&.desktop {
			@media (max-width: 1220px) {
				display: none;
			}
		}

		&.mobile {
			@media (min-width: 1221px) {
				display: none;
			}
		}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	max-width: 650px;
	padding: 2rem 2rem;

	@media (max-width: 1220px) {
		margin: 0 auto;
	}

	@media only screen and (min-width: 768px) {
		padding: 2rem 4rem;
	}
	h2 {
		font-size: 2rem;
		font-weight: 500;
		a {
			color: inherit;
		}
	}
`;

export default ({ image, mobileImage, imageAlt, mainContent }) => (
	<Wrap>
		<div className="image-column">
			<ImageWrap>
				<img className="desktop" src={image} alt={imageAlt} />
				<img className="mobile" src={mobileImage} alt={imageAlt} />
			</ImageWrap>
		</div>
		<div className="content-column">
			<Content dangerouslySetInnerHTML={{ __html: mdToHtml(mainContent) }} />
		</div>
	</Wrap>
);
