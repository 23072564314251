import React from 'react';
import styled from 'styled-components';

import GoogleAdsMastheadForm from './GoogleAdsMastheadForm';

const Wrap = styled.div`
	background-image: url(${(p) => (p.mobile ? p.mobile : p.image)});
	@media only screen and (min-width: 768px) {
		background-image: url(${(p) => p.image});
	}

	@media (max-width: 767px) {
		min-height: auto;
		padding: 7rem 0 6rem;
	}

	background-size: cover;
	background-position: center center;
	min-height: ${(p) => p.height || '400px'};
	padding: 3rem 0 4rem;
	display: flex;
	align-items: center;

	.masthead-title-inner {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		h1 {
			@media (max-width: 767px) {
				font-size: 1.75rem;
				margin-bottom: 0;
				line-height: 1.3;
			}
		}
	}

	.form-column {
		@media (max-width: 767px) {
			display: none;
		}
	}
`;

const MobileWrap = styled.div`
	padding: 2rem 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	@media (min-width: 768px) {
		display: none;
	}
`;

const Title = styled.h1`
	font-size: 2rem;
	color: #ffffff;
	line-height: 1.5;
	max-width: 470px;

	@media only screen and (min-width: 768px) {
		font-size: 3rem;
	}

	@media (max-width: 767px) {
		margin-bottom: 2rem;
	}

	margin: 0;
	font-weight: ${(p) => (p.blog === true ? 700 : 400)};
	color: ${(p) => (p.blog === true ? 'white' : '')};
`;

export default ({ image, title, height, blog, mobile }) => (
	<div>
		<Wrap image={image} height={height} mobile={mobile}>
			<div className="container" style={{ padding: '0 2rem' }}>
				<div className="columns">
					<div className="column is-8">
						<div className="masthead-title-inner">
							<Title blog={blog}>{title}</Title>
						</div>
					</div>
					<div className="column is-4 form-column">
						<GoogleAdsMastheadForm />
					</div>
				</div>
			</div>
		</Wrap>
		<MobileWrap>
			<GoogleAdsMastheadForm />
		</MobileWrap>
	</div>
);
