import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from '../Button';

import common from '../../styles/common';

const Form = styled.div`
	background-color: rgba(97, 120, 151, 0.9);
	padding: 2rem;
	max-width: 35rem;
	margin-top: -1rem;
	margin-bottom: -1rem;

	h2 {
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		margin-top: 0;
	}

	input {
		width: 100%;
		font-size: 1rem;
		border: none;
		padding: 0.75rem;
		margin-bottom: 0.5rem;
		font-family: ${common.baseFont};
	}
	textarea {
		width: 100%;
		font-size: 1rem;
		border: none;
		padding: 0.75rem;
		margin-bottom: 0.5rem;
		resize: none;
		font-family: ${common.baseFont};
	}
	.special {
		display: none;
	}
`;

export default () => {
	const [isThanks, setIsThanks] = useState(false);

	useEffect(() => {
		setIsThanks(window.location.hash === '#thanks');
	}, []);

	return (
		<Form>
			{isThanks && (
				<p
					style={{
						color: 'white',
						backgroundColor: common.spergelRed,
						padding: '1rem',
					}}
				>
					Thank you for your inquiry. We will be in contact with you as soon as
					possible.
				</p>
			)}
			<form
				method="POST"
				data-netlify="true"
				name="Google Ads Page"
				action="/contact#thanks"
				netlify-honeypot="special"
			>
				<h2>
					Let's start a conversation. <br />
					Contact us today.
				</h2>
				<input type="hidden" name="form-name" value="Google Ads Page" />
				<input name="special" className="special" />
				<input
					type="hidden"
					name="subject"
					value="New Form Submission From spergelcorporate.ca"
				/>
				<input type="text" name="name" required placeholder="Name" />
				<input type="email" name="email" required placeholder="Email" />
				<input type="tel" name="phone" required placeholder="Phone" />
				<textarea
					type="text"
					name="message"
					required
					placeholder="Message"
					rows="10"
				/>
				<div style={{ textAlign: 'center' }}>
					<Button type="submit">SUBMIT</Button>
				</div>
			</form>
		</Form>
	);
};
