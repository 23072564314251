import React from 'react';
import styled from 'styled-components';

import common from '../../styles/common';

import ServiceIndustryTile from '../ServiceIndustryTile';

function removeExclusions(services) {
	return services.filter(
		(service) =>
			service.name !== 'Debt Solutions for Sole Proprietors' &&
			service.name !== 'Debt Solutions for Corporations'
	);
}

// Split array in half.
// Larger half will always be first.
// Returns array of halves.
function splitArrayInHalf(arr) {
	const half = Math.ceil(arr.length / 2);
	const firstHalf = arr.slice(0, half);
	const secondHalf = arr.slice(half);
	return [firstHalf, secondHalf];
}

const Inner = styled.div`
	@media ${common.devices.desktop} {
		margin-top: ${(p) => p.offset};
	}
`;

export default ({ services }) => {
	const filteredServices = removeExclusions(services);
	const [first, second] = splitArrayInHalf(filteredServices);
	return (
		<Wrap>
			<div className="container">
				<div className="content-row">
					<h2 style={{ maxWidth: '600px' }}>
						Strategic Debt Solutions, Restructuring and Consulting Services for
						Your Business
					</h2>
					<p>
						For over 30 years, our Licensed Insolvency Trustees have been
						helping all size of businesses identify and solve their business
						debt problems including corporate insolvency and corporate
						bankruptcy. Our breadth of restructuring experience can help your
						business explore all possible business debt solutions. Whether you
						need operational and financial restructuring, insolvency or advisory
						services – let our team help you discover strategic and
						cost-effective solutions.
					</p>
				</div>
				<div className="heading-row">
					<h2>Our Services Include:</h2>
				</div>
				<div className="columns is-gapless">
					<div className="column">
						<Inner>
							{first.map((industry, index) => (
								<ServiceIndustryTile
									title={industry.name}
									image={industry.image}
									slug={industry.slug}
									key={index}
								/>
							))}
						</Inner>
					</div>
					<div className="column">
						<Inner offset={'4rem'}>
							{second.map((industry, index) => (
								<ServiceIndustryTile
									title={industry.name}
									image={industry.image}
									slug={industry.slug}
									key={index}
								/>
							))}
						</Inner>
					</div>
				</div>
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	padding: 4rem 0 6rem;

	@media (max-width: 767px) {
		padding-top: 1rem;
	}

	h2 {
		font-size: 2rem;
	}

	.content-row {
		border-bottom: 2px solid #d0d0d0;
		padding: 0.5rem 0;
		margin: 0 0.5rem;

		p {
			font-family: ${common.baseFont};
			font-size: 1.3rem;
			line-height: 2;
		}
	}

	.heading-row {
		padding: 0.5rem;
		margin-bottom: 1rem;

		h2 {
			text-align: center;
			margin-top: 2rem;
		}
	}
`;
