import React, { useState } from 'react';
import styled from 'styled-components';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';

export default ({ handleUpdate }) => {
	const [value, setValue] = useState('');
	const handleSelect = (address) => {
		setValue(address);
		geocodeByAddress(address)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				handleUpdate(latLng);
			})
			.catch((error) => console.error('Error', error));
	};
	return (
		<SearchSection>
			<h2>Find a Spergel office near you:</h2>
			<PlacesAutocomplete
				value={value}
				onChange={(address) => setValue(address)}
				onSelect={(address) => handleSelect(address)}
				searchOptions={{ componentRestrictions: { country: 'ca' } }}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div className="main-container">
						<input
							{...getInputProps({
								placeholder: 'Enter your city name or address...',
								className: 'location-search-input',
							})}
						/>
						<div className="dropdown-container">
							{loading && <div>Loading...</div>}
							{suggestions.map((suggestion) => {
								return (
									<div
										className="suggestion"
										{...getSuggestionItemProps(suggestion)}
									>
										<span>{suggestion.description}</span>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
		</SearchSection>
	);
};

const SearchSection = styled.div`
	background-color: #f2f2f2;
	padding: 4rem 1rem;
	text-align: center;
	.main-container {
		width: 100%;
		max-width: 40rem;
		position: relative;
		margin: 0 auto;
	}
	input {
		padding: 1rem;
		border: none;
		border-radius: 0;
		font-size: 1rem;
		width: 100%;
	}
	.dropdown-container {
		width: 100%;
		position: absolute;
		background-color: white;
		z-index: 100;
	}
	.suggestion {
		text-align: left;
		padding: 1rem;
		&:hover {
			background-color: #f2f2f2;
		}
	}
`;
