import React from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';

export default ({ locations, center, zoom = 14 }) => {
	return (
		<GoogleMapReact
			bootstrapURLKeys={{ key: 'AIzaSyDa4sBxkA4L0J5j4J6-Qsbnj5i_oEmrKmc' }}
			center={center}
			defaultZoom={zoom}
		>
			{locations.map((location) => (
				<Marker
					text={location.city}
					key={location.city}
					lat={location.coords.latitude}
					lng={location.coords.longitude}
				/>
			))}
		</GoogleMapReact>
	);
};

const Marker = ({ text }) => (
	<MarkerStyle>
		<img src="/img/marker.svg" alt="Marker" />
	</MarkerStyle>
);

const MarkerStyle = styled.div`
	position: relative;
	top: -48px;
	left: -16px;
	img {
		width: 32px;
		height: 48px;
	}
`;
