import React, { useState } from 'react';
import styled from 'styled-components';

import Map from '../components/Map';
import LocationTile from '../components/LocationTile';

//import Columns from 'react-bulma-components/lib/components/columns';
import LocationSearchBox from './LocationSearchBox';

export default ({ locations, zoom }) => {
	const [componentState, setComponentState] = useState({
		locationList: locations,
		center: {
			lat: locations[0].coords.latitude,
			lng: locations[0].coords.longitude,
		},
	});

	const update = (coords) => {
		const newLocations = sortLocations(componentState.locationList, coords);
		const newCenter = {
			lat: newLocations[0].coords.latitude,
			lng: newLocations[0].coords.longitude,
		};
		setComponentState({
			locationList: newLocations,
			center: newCenter,
		});
	};

	const updateCenter = (center) => {
		setComponentState({
			locationList: componentState.locationList,
			center: center,
		});
	};

	return (
		<>
			<LocationSearchBox handleUpdate={update} />
			<MapAndLocations>
				<div className="columns">
					<div className="column is-half">
						<div className="inner">
							<h2>Our locations:</h2>
							{componentState.locationList.map((location) => (
								<LocationTile
									key={location.city}
									location={location}
									setCenter={updateCenter}
								/>
							))}
						</div>
					</div>
					<div className="column is-half">
						<div className="inner">
							<Map
								locations={locations}
								center={componentState.center}
								zoom={zoom}
							/>
						</div>
					</div>
				</div>
			</MapAndLocations>
		</>
	);
};

const MapAndLocations = styled.div`
	padding: 0 1rem;
	.inner {
		height: 500px;
		overflow: scroll;
	}
	h2 {
		padding: 2rem;
		max-width: 30rem;
		margin: 0.5rem auto;
	}
`;

const calcDistance = (lat1, lon1, lat2, lon2, unit) => {
	if (lat1 === lat2 && lon1 === lon2) {
		return 0;
	} else {
		var radlat1 = (Math.PI * lat1) / 180;
		var radlat2 = (Math.PI * lat2) / 180;
		var theta = lon1 - lon2;
		var radtheta = (Math.PI * theta) / 180;
		var dist =
			Math.sin(radlat1) * Math.sin(radlat2) +
			Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = (dist * 180) / Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit === 'K') {
			dist = dist * 1.609344;
		}
		if (unit === 'N') {
			dist = dist * 0.8684;
		}
		return dist;
	}
};

const sortLocations = (locations, coords) => {
	let locationList = locations.map((loc) => {
		loc.distance = 0;
		if (coords) {
			loc.distance = calcDistance(
				coords.lat,
				coords.lng,
				loc.coords.latitude,
				loc.coords.longitude,
				'K'
			);
		}
		return loc;
	});

	return locationList.sort((a, b) => {
		if (a.distance < b.distance) {
			return -1;
		}
		if (a.distance > b.distance) {
			return 1;
		}
		return 0;
	});
};
